<div (click)="modalService.toggleModalIfPossible(modal_name)"
     class="modal"
     *ngIf="this.modalService.modalName.value === modal_name">

  <div class="form" (click)="modalService.stopPropagation($event)">

    <!-- CHANGE HISTORY MODAL -->
    <div *ngIf="modal_name === modalService.bulkMailHistoryModalName">
      <h3 *ngIf="modal_title"> {{modal_title}} </h3>
      <h3 *ngIf="modal_name && !modal_title"> {{modal_name}} </h3>

      <div id="layout">
        <div id="titles">
          <div class="item">ID</div>
          <div class="item">CREATION TIME</div>
          <div class="item">ID OF TEMPLATE</div>
          <div class="item">CAMPAIGN NAME</div>
          <div class="item">CAMPAIGN DESCRIPTION</div>
          <div class="item">OPENED MAIL / SENT TO USERS AMOUNT</div>
        </div>
        <div class="infinite-scrolling">
          <div class="contents" *ngFor="let bulkMailHistory of this.mailService.bulkMailHistories">
            <div class="item">
              {{bulkMailHistory.id}}
            </div>
            <div class="item">
              {{bulkMailHistory.created_at}}
            </div>
            <div class="item">
              {{bulkMailHistory.sent_template_id}}
            </div>
            <div class="item">
              {{bulkMailHistory.campaign_name}}
            </div>
            <div class="item">
              {{bulkMailHistory.campaign_description}}
            </div>
            <div class="item">
              {{bulkMailHistory.amount_users_opened}} / {{bulkMailHistory.amount_users_sent_to}}
            </div>
          </div>
        </div>

        <app-primary-button [text]=primary_action_text
                            (click)="primary_action_function.emit() //noinspection UnresolvedFunction"></app-primary-button>
      </div>
    </div>

    <!--  TEMPLATE & PROJECT MODAL -->
    <div
      *ngIf="modal_name === modalService.createTemplateModalName || modal_name === modalService.editTemplateModalName || modal_name === modalService.createProjectModalName">
      <h3 *ngIf="modal_title"> {{modal_title}} </h3>
      <h3 *ngIf="modal_name && !modal_title"> {{modal_name}} </h3>

      <!-- TEMPLATE INPUTS -->
      <app-input
        *ngIf="modal_name === modalService.createTemplateModalName || modal_name === modalService.editTemplateModalName"
        [(ng_model)]="this.templateService.newTemplateName"
        label_text="Name">
      </app-input>

      <app-generic-select
        *ngIf="templateService.types && modal_name === modalService.createTemplateModalName || modal_name === modalService.editTemplateModalName"
        label_text="Type"
        [(ng_model)]="this.templateService.newTemplateType"
        [items_array]="templateService.types"
        item_name="TYPE">
      </app-generic-select>

      <app-generic-select
        *ngIf="templateService.projects && modal_name === modalService.createTemplateModalName || modal_name === modalService.editTemplateModalName"
        label_text="Project"
        [items_array]="templateService.projects"
        item_name="PROJECT"
        [(ng_model)]="this.templateService.newTemplateProject">
      </app-generic-select>

      <!-- PROJECT INPUTS -->
      <app-input *ngIf="modal_name === modalService.createProjectModalName"
                 [(ng_model)]="this.templateService.newProjectName"
                 label_text="Project Name">
      </app-input>

      <app-primary-button [text]=primary_action_text
                          (click)="primary_action_function.emit() //noinspection UnresolvedFunction"></app-primary-button>
      <app-primary-button text="CANCEL"
                          (click)="this.modalService.toggleModalIfPossible(modal_name)"></app-primary-button>
    </div>


    <!-- CONFIRM ACTION MODAL -->
    <div
      *ngIf="modal_name === modalService.confirmActionSaveModalName || modal_name === modalService.confirmActionDeleteModalName">
      <h3 *ngIf="modal_title"> {{modal_title}} </h3>
      <h3 *ngIf="modal_name && !modal_title"> {{modal_name}} </h3>


      <app-primary-button [text]=primary_action_text
                          (click)="primary_action_function.emit() //noinspection UnresolvedFunction"></app-primary-button>
      <app-primary-button text="CANCEL"
                          (click)="this.modalService.toggleModalIfPossible(modal_name)"></app-primary-button>
    </div>

  </div>
</div>
