import {environment} from "../environments/environment";

export const user_urls = {
  getStatusUrl: environment.apiUrl + '/user/get-auth-status',
  registerUrl: environment.apiUrl + '/user/register',
  loginUrl: environment.apiUrl + '/user/login',
  logoutUrl: environment.apiUrl + '/user/logout',
};

export const project_urls = {
  getAllUrl: environment.apiUrl + '/projects/all',
  createNewUrl: environment.apiUrl + '/projects/add',
  editByIdUrl: environment.apiUrl + '/projects/edit',
  deleteByIdUrl: environment.apiUrl + '/projects/delete'
};

export const template_urls = {
  getAllUrl: environment.apiUrl + '/templates/all',
  getAllByProjectIdUrl: environment.apiUrl + '/templates/by-project-id',
  getByIdUrl: environment.apiUrl + '/templates/by-id',
  getFullByIdUrl: environment.apiUrl + '/templates/full-by-id',
  getAllUniqueReceivers: environment.apiUrl + '/templates/all-unique-receivers',
  createNewUrl: environment.apiUrl + '/templates/add',
  editByIdUrl: environment.apiUrl + '/templates/edit',
  deleteByIdUrl: environment.apiUrl + '/templates/delete',
  syncTranslationsUrl: environment.apiUrl + '/templates/sync-translations',
};

export const language_urls = {
  getAllUrl: environment.apiUrl + '/languages/all',
};

export const email_urls = {
  sendMailUrl: environment.apiUrl + '/mail/sendmail',
  sendAllMailUrl: environment.apiUrl + '/mail/sendall-by-project',
  sendBulkMailUrl: environment.apiUrl + '/mail/send-bulk',
  getBulkMailHistory: environment.apiUrl + '/mail/get-bulk-mail-history'
};

export const type_urls = {
  getAllUrl: environment.apiUrl + '/types/all',
};

export const textkeys_urls = {
  getAllByProjectIdUrl: environment.apiUrl + '/textkeys/by-project-id'
};


// svgPathD values for implementing SVGs into the app-primary-button component, icons taken from https://fonts.google.com/icons
export const svgPathsD = {
  mail: 'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z',
  templates: 'M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z',
  projects: 'M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h4.2q.325-.9 1.088-1.45Q11.05 1 12 1t1.713.55Q14.475 2.1 14.8 3H19q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm2-4h7v-2H7Zm0-4h10v-2H7Zm0-4h10V7H7Zm5-4.75q.325 0 .538-.213.212-.212.212-.537 0-.325-.212-.538-.213-.212-.538-.212-.325 0-.537.212-.213.213-.213.538 0 .325.213.537.212.213.537.213Z',
  logout: 'M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h7v2H5v14h7v2Zm11-4-1.375-1.45 2.55-2.55H9v-2h8.175l-2.55-2.55L16 7l5 5Z',
  delete: 'M7 21q-.825 0-1.412-.587Q5 19.825 5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413Q17.825 21 17 21ZM17 6H7v13h10ZM9 17h2V8H9Zm4 0h2V8h-2ZM7 6v13Z',
  add: 'M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z',
  copy: 'M200 976q-33 0-56.5-23.5T120 896V336h80v560h440v80H200Zm160-160q-33 0-56.5-23.5T280 736V256q0-33 23.5-56.5T360 176h360q33 0 56.5 23.5T800 256v480q0 33-23.5 56.5T720 816H360Zm0-80h360V256H360v480Zm0 0V256v480Z',
  sync: 'M160 896v-80h110l-16-14q-52-46-73-105t-21-119q0-111 66.5-197.5T400 266v84q-72 26-116 88.5T240 578q0 45 17 87.5t53 78.5l10 10v-98h80v240H160Zm400-10v-84q72-26 116-88.5T720 574q0-45-17-87.5T650 408l-10-10v98h-80V256h240v80H690l16 14q49 49 71.5 106.5T800 574q0 111-66.5 197.5T560 886Z',
  campaign: 'M720 616v-80h160v80H720Zm48 280-128-96 48-64 128 96-48 64Zm-80-480-48-64 128-96 48 64-128 96ZM200 856V696h-40q-33 0-56.5-23.5T80 616v-80q0-33 23.5-56.5T160 456h160l200-120v480L320 696h-40v160h-80Zm100-280Zm260 134V442q27 24 43.5 58.5T620 576q0 41-16.5 75.5T560 710ZM160 536v80h182l98 58V478l-98 58H160Z'
};

export const svgViewBoxes = {
  viewbox_0_0_24_24: '0 0 24 24',
  viewbox_0_96_960_960: '0 96 960 960'
};

export const svgs = {
  mail: {
    svgPathD: svgPathsD.mail,
    svgViewBox: svgViewBoxes.viewbox_0_0_24_24
  },
  templates: {
    svgPathD: svgPathsD.templates,
    svgViewBox: svgViewBoxes.viewbox_0_0_24_24
  },
  projects: {
    svgPathD: svgPathsD.projects,
    svgViewBox: svgViewBoxes.viewbox_0_0_24_24
  },
  logout: {
    svgPathD: svgPathsD.logout,
    svgViewBox: svgViewBoxes.viewbox_0_0_24_24
  },
  delete: {
    svgPathD: svgPathsD.delete,
    svgViewBox: svgViewBoxes.viewbox_0_0_24_24
  },
  add: {
    svgPathD: svgPathsD.add,
    svgViewBox: svgViewBoxes.viewbox_0_0_24_24
  },
  copy: {
    svgPathD: svgPathsD.copy,
    svgViewBox: svgViewBoxes.viewbox_0_96_960_960
  },
  sync: {
    svgPathD: svgPathsD.sync,
    svgViewBox: svgViewBoxes.viewbox_0_96_960_960
    },
  campaign: {
    svgPathD: svgPathsD.campaign,
    svgViewBox: svgViewBoxes.viewbox_0_96_960_960
    }
};
