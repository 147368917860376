<div id="text">
  <h2>SENDING MAILS</h2>
  <p>
    You can send mails for testing purposes from this page. <br> <br>
    First you have to select all 4 options, receiver, project, language and template.
  </p>
  <ul>
    <li>Receiver: The type of user to receive the message (Sugardaddy, Sugarmommy etc.) this is for translation purposes
      because some languages are gendered.
    </li>
    <li>Project: To get all templates corresponding to a project.</li>
    <li>Language: The language in which you want to send the template.</li>
    <li>Template: The template which you want to send or edit on the "TEMPLATES" page.</li>
  </ul>
  <p>
    After you selected a template you will see a window to the left of the preview, in which you have to type in an
    e-mail address that is supposed to receive the e-mail and the subject for the email. <br>
    Below those inputs you can insert placeholders that will be put into the preview.
  </p>

</div>

<hr>

<div id="buttons-container">
  <app-primary-button class="control-btn" text="SEND EMAIL" (click)="this.mailService.sendMail()"></app-primary-button>
  <!--  <app-primary-button class="control-btn" text="SEND ALL"-->
  <!--                      (click)="this.mailService.sendAllMails()"></app-primary-button>-->
</div>


<div id="placeholder-template-container">
  <div id="template-container">
    <app-template-preview></app-template-preview>
  </div>

  <div id="placeholders_container" *ngIf="(this.templateService.placeholdersObject | keyvalue)?.length">

    <app-input [(ng_model)]="mailService.recipientEmail" label_text="E-Mail Address">
    </app-input>

    <app-input [(ng_model)]="templateService.subject" label_text="Subject">
    </app-input>

    <hr>

    <div class="placeholder_with_controls"
         *ngFor="let placeholder of templateService.placeholdersObject | keyvalue; trackBy:templateService.getPlaceholderKey">
      <app-input label_text="{{placeholder.key}}" [(ng_model)]="placeholder.value"
                 (keyup)="templateService.addPlaceholder(placeholder.key, placeholder.value)">

      </app-input>
      <app-primary-button class="delete-placeholder-btn" svgPathD="{{ this.svgService.svgs.delete.svgPathD }}" text=""
                          (click)="templateService.removePlaceholder(placeholder.key)"
                          svgViewBox="{{ this.svgService.svgs.delete.svgViewBox }}">
      </app-primary-button>
    </div>

    <app-primary-button id="insert-btn" text="INSERT" (click)="templateService.setFullTemplate()"></app-primary-button>
  </div>

</div>
