<app-primary-button
  text="SEND MAIL"
  activeUrlLink="/dashboard/sendmail"
  routerLink="/dashboard/sendmail"
  svgPathD="{{ this.svgService.svgs.mail.svgPathD }}"
  svgViewBox="{{this.svgService.svgs.mail.svgViewBox}}">
</app-primary-button>

<app-primary-button
  text="BULK EMAIL"
  activeUrlLink="/dashboard/bulk-email"
  routerLink="/dashboard/bulk-email"
  svgPathD="{{ this.svgService.svgs.campaign.svgPathD }}"
  svgViewBox="{{this.svgService.svgs.campaign.svgViewBox}}">
</app-primary-button>

<app-primary-button
  text="TEMPLATES"
  activeUrlLink="/dashboard/templates"
  routerLink="/dashboard/templates"
  svgPathD="{{ this.svgService.svgs.templates.svgPathD }}"
  svgViewBox="{{this.svgService.svgs.templates.svgViewBox}}">
</app-primary-button>

<app-primary-button
  text="PROJECTS"
  activeUrlLink="/dashboard/projects"
  routerLink="/dashboard/projects"
  svgPathD="{{ this.svgService.svgs.projects.svgPathD }}"
  svgViewBox="{{this.svgService.svgs.projects.svgViewBox}}">
</app-primary-button>

<app-primary-button
  text="LOGOUT"
  activeUrlLink="/logout"
  svgPathD="{{ this.svgService.svgs.logout.svgPathD }}"
  svgViewBox="{{this.svgService.svgs.logout.svgViewBox}}"
  (click)="logout()">
</app-primary-button>

<div id="user-info" *ngIf="userService.loggedInUser">
  <p><b>Logged in as:</b></p>
  <p>{{ this.userService.loggedInUser.email }}</p>
  <p>{{ this.userService.loggedInUser.name }}</p>
</div>
