import {Component} from '@angular/core'
import {TemplateService} from "../../services/template.service"

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss'],
})
export class TemplatePreviewComponent {

  constructor(private templateService: TemplateService) { }

  getTemplatePreviewHtml(): string {
    if (this.templateService.selectedReceiver === undefined) {
      return 'Please select a receiver'
    }
    if (this.templateService.selectedProject === undefined) {
      return 'Please select a project'
    }
    if (this.templateService.selectedLanguage === undefined) {
      return 'Please select a language'
    }
    if (this.templateService.currentTemplatePreview.value === undefined) {
      return 'Please select a template'
    }
    else {
      if (this.templateService.currentTemplatePreview.value.html_content !== undefined) {
        return this.templateService.currentTemplatePreview.value.html_content
      }
      else {
        return 'Selected template mjml content is undefined'
      }
    }

  }

}
