import {Component} from '@angular/core'
import {TemplateService} from "../../services/template.service"
import {UserService} from "../../services/user.service"
import {language_urls, project_urls, template_urls, type_urls} from "../../app.constants"
import {LoadingService} from "../../services/loading.service"
import {MailService} from "../../services/mail.service";

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent {

  constructor(public templateService: TemplateService, public userService: UserService, private loadingService: LoadingService, public mailService: MailService) {
    this.getAllData()
  }

  getAllData(): void {
    this.loadingService.startLoading();
    Promise.all([
      this.templateService.getAllCall(template_urls.getAllUniqueReceivers),
      this.templateService.getAllCall(project_urls.getAllUrl),
      this.templateService.getAllCall(language_urls.getAllUrl),
      this.templateService.getAllCall(type_urls.getAllUrl),
      this.mailService.getBulkMailHistoryCall(),
    ])
      .then((results) => {
          this.templateService.uniqueReceivers = results[0].unique_receivers;
          this.templateService.projects = results[1].projects;
          this.templateService.languages = results[2].languages;
          this.templateService.types = results[3].types;
          this.mailService.bulkMailHistories = results[4].bulk_mail_history;
          this.loadingService.finishLoading();
        }
      )
  }

}
