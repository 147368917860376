import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TemplateService} from "../../services/template.service";

@Component({
  selector: 'app-input[ng_model]',
  templateUrl: './generic-input.component.html',
  styleUrls: ['./generic-input.component.scss']
})
export class GenericInputComponent {

  @Input() label_text: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() ng_model!: string | undefined;
  @Output() ng_modelChange: EventEmitter<string> = new EventEmitter<string>();


  constructor(public templateService: TemplateService) { }

  emitNgModel() {
    this.ng_modelChange.emit(this.ng_model)
  }

}
