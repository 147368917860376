<form>

  <div id="email">
    <input [(ngModel)]="userEmailInput"
           (keyup.enter)="userService.loginWithUser(userEmailInput, userPasswordInput)"
           type="text"
           placeholder="Enter Email"
           name="email"
           required>
  </div>

  <div id="password">
    <input [(ngModel)]="userPasswordInput"
           (keyup.enter)="userService.loginWithUser(userEmailInput, userPasswordInput)"
           type="password"
           placeholder="Enter Password"
           name="password"
           required>
  </div>

  <div>
    <app-primary-button type="button" text="LOGIN"
                        (click)="this.userService.loginWithUser(userEmailInput, userPasswordInput)">
    </app-primary-button>
  </div>


</form>

<!--  TODO Maybe adding these functionalities in the future if it's needed, JWTs expire after 24h to increase security,-->
<!--    in case someone wants to directly use the API he should preferably be issued a token with a longer/indefinite expiration time manually-->

<!--
  <div id="remember-me">
    <input type="checkbox" checked="checked">
    Remember me
   </div>
-->

<!--  <div id="forgot-password">-->
<!--  <a href="#"> Forgot password? </a>-->
<!--  </div>-->

<!--  <div id="register">-->
<!--    <a [routerLink]="['/register']"> Register </a>-->
<!--  </div>-->

