import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {JsonResponse, UserObject} from '../interfaces/user-interfaces';
import {lastValueFrom} from 'rxjs';
import {Router} from '@angular/router';
import {user_urls} from '../app.constants';
import {AlertService} from './alert.service';
import {UtilityService} from './utility.service';
import {LoadingService} from "./loading.service";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // Headers
  bearerToken: string | null = this.getTokenFromLocalStorage();
  loggedInUser: UserObject | undefined = undefined;

  constructor(
    private http: HttpClient,
    public router: Router,
    private alertService: AlertService,
    private utilityService: UtilityService,
    private loadingService: LoadingService
  ) {
  }

  async isUserAuthorized(): Promise<JsonResponse> {
    return await lastValueFrom(
      this.http.get<JsonResponse>(user_urls.getStatusUrl, {
        headers: this.getAuthHeaders(),
        observe: 'body',
        responseType: 'json',
      })
    );
  }

  // Promise Solution
  async loginCall(
    email: string | null = null,
    password: string | null = null
  ): Promise<JsonResponse> {
    return await lastValueFrom(
      this.http.post<JsonResponse>(
        user_urls.loginUrl,
        {
          email: email,
          password: password,
        },
        {
          headers: this.getAuthHeaders(),
          observe: 'body',
          responseType: 'json',
        }
      )
    )
  }

  // Observable Solution for reference
  // login(email: string | null = null, password: string | null = null): Observable<UserResponse> {
  //   return this.http.post<UserResponse>(this.loginUrl,{email: email, password: password}, {headers: this.getAuthHeaders()}).pipe(catchError(this.handleErrors))
  // }

  loginWithUser(userEmailInput: string, userPasswordInput: string): void {
    this.loadingService.startLoading();

    this.loginCall(userEmailInput, userPasswordInput)
      .then((response) => {
        if (
          response.token &&
          !response.error
        ) {
          localStorage.setItem('bearer_token', response.token);
          this.bearerToken = 'Bearer ' + response.token;
          this.loggedInUser = response.user;
          this.router.navigate(['/dashboard/sendmail']);
        }
        this.loadingService.finishLoading();
      })
      .catch((errorResponse) => {
        this.alertService.handleErrorResponse(errorResponse);
        this.loadingService.finishLoading();
      });
  }

  // Observable solution for reference
  // loginWithUser(): void {
  //   this.userSubscription = this.userService.login(this.userEmailInput, this.userPasswordInput).subscribe(
  //     {
  //       next: (response: { token: string token_expiration_date: string }) => {
  //         localStorage.setItem('bearer_token', response.token)
  //         localStorage.setItem('token_expiration_date', response.token_expiration_date)
  //         this.router.navigate(['/dashboard/sendmail'])
  //       },
  //       error: (errorResponse: any) => console.log(errorResponse.message),
  //       complete: () => console.info('complete')
  //     })
  // }

  async logout(): Promise<JsonResponse> {
    return await lastValueFrom(
      this.http.post<JsonResponse>(user_urls.logoutUrl, null, {
        headers: this.getAuthHeaders(),
      })
    );
  }

  getAuthHeaders(): HttpHeaders {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (this.bearerToken !== null) {
      headers = headers.set('Authorization', this.bearerToken);
    }
    return headers;
  }

  getTokenFromLocalStorage(): string {
    this.bearerToken = localStorage.getItem('bearer_token');
    return 'Bearer ' + this.bearerToken;
  }

  // registerCall(name: string | null = null, email: string | null = null, password: string | null = null, password_confirmation: string | null = null){
  //   return this.http.post<JsonResponse>(user_urls.registerUrl, {
  //       name: name,
  //       email: email,
  //       password: password,
  //       password_confirmation: password_confirmation
  //     })
  // }
  //
  // registerWithUser(userNameInput: string | null, userEmailInput: string | null, userPasswordInput: string | null, userPasswordConfirmationInput: string | null): void {
  //   this.registerCall(userNameInput, userEmailInput, userPasswordInput, userPasswordConfirmationInput)
  //     .subscribe(response =>{
  //       if (response.error && response.errortxt !== undefined) {
  //         this.alertService.setAlertMessage(response.errortxt)
  //       }
  //       else if (response.token && response.token_expiration_date) {
  //         localStorage.setItem('bearer_token', response.token)
  //         localStorage.setItem('token_expiration_date', response.token_expiration_date)
  //         this.router.navigate(['/dashboard/sendmail'])
  //       }})
  // }
}
