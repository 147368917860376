<img routerLink="/dashboard/sendmail" src="assets/images/msd-emails-logo.png" alt="MSD Emails Logo">

<!--<div class="select-elements">-->
<!--  <app-select-receiver></app-select-receiver>-->
<!--  <app-select-project> </app-select-project>-->
<!--  <app-select-language> </app-select-language>-->
<!--  <app-select-template> </app-select-template>-->
<!--</div>-->

<div class="select-elements">
  <app-generic-select
    item_name="RECEIVER"
    [(ng_model)]="templateService.selectedReceiver"
    [items_array]="templateService.uniqueReceivers"
    label_text="Receiver">
  </app-generic-select>

  <app-generic-select
    item_name="PROJECT"
    [(ng_model)]="templateService.selectedProject"
    [items_array]="templateService.projects"
    label_text="Project">>
  </app-generic-select>

  <app-generic-select
    item_name="LANGUAGE"
    [(ng_model)]="templateService.selectedLanguage"
    [items_array]="templateService.languages"
    label_text="Language">>
  </app-generic-select>

  <app-generic-select
    item_name="TEMPLATE"
    [(ng_model)]="templateService.selectedTemplate"
    [items_array]="templateService.templates"
    label_text="Template">
  </app-generic-select>

</div>

