import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TemplatesPageComponent} from "./pages/templates-page/templates-page.component";
import {SendmailPageComponent} from "./pages/sendmail-page/sendmail-page.component";
import {LoginPageComponent} from "./pages/login-page/login-page.component";
import {DashboardPageComponent} from "./pages/dashboard-page/dashboard-page.component";
import {NotFoundPageComponent} from "./pages/page-not-found/not-found-page.component";
import {AuthGuardService} from "./services/auth-guard.service";
import {ProjectsPageComponent} from "./pages/projects-page/projects-page.component";
import {BulkEmailPageComponent} from "./pages/bulk-email-page/bulk-email-page.component";

const routes: Routes = [
  // Paths that are reachable while unauthenticated
  { path: 'login', component: LoginPageComponent},
  // { path: 'register', component: RegisterComponent},
  { path: '', redirectTo: 'login', pathMatch: 'full'}, // redirect to login page

  // Paths that are only reachable while authenticated
  {path: '', canActivate: [AuthGuardService], children: [
    { path: 'dashboard', component: DashboardPageComponent,
      children: [
        { path: 'sendmail', component:  SendmailPageComponent },
        { path: 'templates', component: TemplatesPageComponent },
        { path: 'projects', component: ProjectsPageComponent },
        { path: 'bulk-email', component: BulkEmailPageComponent },
      ]},
  ]},

  { path: '**', component: NotFoundPageComponent }, // catch all page not found
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
