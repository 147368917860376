import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ModalService} from "../../services/modal.service";
import {TemplateService} from "../../services/template.service";
import {AlertService} from "../../services/alert.service";
import {MailService} from "../../services/mail.service";

@Component({
  selector: 'app-generic-modal[modal_name][primary_action_text][primary_action_function]',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent {

  constructor(public modalService: ModalService, public templateService: TemplateService, public alertService: AlertService, public mailService: MailService) {
  }

  @Input() modal_name!: string; // Used to identify specific modals (CREATE TEMPLATE, UPDATE TEMPLATE etc.)
  @Input() modal_title: string | undefined; // Used for the title on top of the modal, if left empty modal_name will be used instead
  @Input() primary_action_text!: string; // Text used for the primary action (UPDATE, DELETE etc.)
  @Output() primary_action_function = new EventEmitter<Function>; // Function that should be executed when pressing the primary button
}
