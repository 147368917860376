import {Component} from '@angular/core'
import {UserService} from "../../services/user.service"
import {Router} from "@angular/router"
import {AlertService} from "../../services/alert.service"

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  userEmailInput: string = '';
  userPasswordInput: string = '';

  // userSubscription: Subscription | null = null
  // For Observable solution reference, remember to unsubscribe inside ngOnDestroy() to prevent memory leaks

  constructor(public userService:UserService, public router:Router, public alertService: AlertService) {}
}
