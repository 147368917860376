<div class="text-with-select-horizontal">

  <label *ngIf="label_text !== undefined">
    {{ label_text }}
  </label>

  <select [(ngModel)]="ng_model" (ngModelChange)="this.emitNgModel()" [compareWith]="utilityService.compareItems">

    <option disabled [ngValue]="undefined">
      SELECT {{ item_name }}
    </option>

    <option [ngValue]="item" *ngFor="let item of items_array">
      {{item.id}} | {{ item.name }}
    </option>

  </select>

</div>
