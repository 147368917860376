<!-- ##TODO The color values should be taken directly from vars, find out how to do this -->

<button
  [style.background-color]="router.url.includes(activeUrlLink) ? '#C82151 !important' : '#000000' //noinspection UnresolvedVariable"
  class="primary-btn"
  [routerLink]=routerLink
  type="{{ type }}"
  onsubmit="return false"
>

  <svg *ngIf="svgPathD !== undefined && svgViewBox !== undefined" xmlns="http://www.w3.org/2000/svg" width="24" height="24" [attr.viewBox]="svgViewBox">
    <path fill="#FFFFFF" [attr.d]="svgPathD"/>
  </svg>

  <p>{{ text }}</p>

</button>

