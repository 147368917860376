import {Component, Input} from '@angular/core';
import {AlertService} from "../../services/alert.service";
import {AlertObject} from "../../interfaces/user-interfaces";

@Component({
  selector: 'app-alert-message[alert_object]',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent {

  @Input() alert_object!: AlertObject;

  constructor(public alertService: AlertService) { }

}
