import {Injectable, Injector} from '@angular/core';
import {UtilityService} from "./utility.service";
import {AlertObject, BulkMailHistoryObject, BulkMailHistoryResponse, JsonResponse} from "../interfaces/user-interfaces";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  activeAlerts: AlertObject[] = [];
  errorId: number = 1;

  constructor(private injector: Injector) {
  }

  addAlert(alertMessage: string, errors?: Object) {
    let newAlert: AlertObject = {
      id: this.errorId,
      alert_message: alertMessage,
      errors: errors
    };

    ++this.errorId;

    // If maximum alerts have been reached delete the first one that was created
    const maximumActiveAlerts = 20;
    if (this.activeAlerts.length >= maximumActiveAlerts) {
      this.activeAlerts.shift();
    }

    this.activeAlerts.push(newAlert);
  }

  deleteAlert(alertId: number) {
    if (this.activeAlerts) {
      const utilityService = this.injector.get(UtilityService);
      this.activeAlerts = utilityService.deleteAlertObjectFromArrayByObjectId(this.activeAlerts, alertId);
    }
  }


  handleResponse(response: JsonResponse | BulkMailHistoryResponse) {
    if (response.message) {
      this.addAlert(response.message);
    }
  }

  handleErrorResponse(response: HttpErrorResponse) {
    if (response.error.message) {
      this.addAlert(response.error.message, response.error.errors);
    }
  }
}
