import {Component} from '@angular/core';
import {AlertService} from "./services/alert.service";
import {LoadingService} from "./services/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public alertService: AlertService, public loadingService: LoadingService) {


  }

  title = 'email-tool-frontend'

}
