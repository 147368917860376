import { Component } from '@angular/core'
import {ModalService} from "../../services/modal.service"
import {TemplateService} from "../../services/template.service"
import {AlertService} from "../../services/alert.service"
import {SvgService} from "../../services/svg.service"

@Component({
  selector: 'app-templates-page',
  templateUrl: './templates-page.component.html',
  styleUrls: ['./templates-page.component.scss']
})
export class TemplatesPageComponent {

  constructor(public modalService: ModalService, public templateService: TemplateService, public svgService: SvgService) { }

}
