<div id="error-message-container" *ngIf="alert_object !== undefined">
  <div id="errors-container">
    <p> {{alert_object.alert_message}}</p>
      <ul *ngIf="alert_object.errors">
        <li *ngFor="let error of alert_object.errors | keyvalue">
          {{error.key}}: {{error.value}}
        </li>
      </ul>
  </div>

  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" (click)="alertService.deleteAlert(alert_object.id)">
    <path fill="#FFFFFF" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
  </svg>
</div>
