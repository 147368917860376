import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from "@angular/common/http";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SideBarComponent} from './components/side-bar/side-bar.component';
import {TemplatesPageComponent} from './pages/templates-page/templates-page.component';
import {SendmailPageComponent} from './pages/sendmail-page/sendmail-page.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PrimaryButtonComponent} from './components/primary-button/primary-button.component';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {CookieService} from "ngx-cookie-service";
import {NotFoundPageComponent} from './pages/page-not-found/not-found-page.component';
import {TemplatePreviewComponent} from './components/template-preview/template-preview.component';
import {DashboardPageComponent} from './pages/dashboard-page/dashboard-page.component';
import {MjmlInputComponent} from './components/mjml-input/mjml-input.component';
import {TopBarComponent} from './components/top-bar/top-bar.component';
import {AlertMessageComponent} from './components/alert-message/alert-message.component';
import {HtmlContentPipe} from './pipes/html-content.pipe';
import {ProjectsPageComponent} from './pages/projects-page/projects-page.component';
import {GenericSelectComponent} from './components/generic-select/generic-select.component';
import {GenericModalComponent} from './components/generic-modal/generic-modal.component';
import {GenericInputComponent} from './components/generic-input/generic-input.component';
import { ReverseArrayPipe } from './pipes/reverse-array.pipe';
import { BulkEmailPageComponent } from './pages/bulk-email-page/bulk-email-page.component';

@NgModule({
  declarations: [
    AppComponent,
    SideBarComponent,
    TemplatesPageComponent,
    SendmailPageComponent,
    PrimaryButtonComponent,
    LoginPageComponent,
    NotFoundPageComponent,
    TemplatePreviewComponent,
    DashboardPageComponent,
    MjmlInputComponent,
    TopBarComponent,
    AlertMessageComponent,
    HtmlContentPipe,
    ProjectsPageComponent,
    GenericSelectComponent,
    GenericModalComponent,
    GenericInputComponent,
    ReverseArrayPipe,
    BulkEmailPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    CommonModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
