import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TemplateService} from "./template.service";
import {AlertService} from "./alert.service";
import {MailService} from "./mail.service";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  createTemplateModalName = 'CREATE TEMPLATE';
  createProjectModalName = 'CREATE PROJECT';
  editTemplateModalName = 'UPDATE TEMPLATE';
  confirmActionSaveModalName = 'CONFIRM ACTION SAVE';
  confirmActionDeleteModalName = 'CONFIRM ACTION DELETE';
  bulkMailHistoryModalName = 'BULK MAIL HISTORY';

  constructor(private templateService: TemplateService, private alertService: AlertService, private mailService: MailService) {
  }

  toggleModal(modalName: string): void {

    if (this.modalName.value === '') {
      this.setModalTemplateValues(modalName);

      this.showModal(modalName);
    } else {
      this.hideModal();
    }
  }

  toggleModalIfPossible(modalName: string): void {
    if (this.errorOccurredOnModalInit(modalName)) {
      return;
    } else {
      this.toggleModal(modalName);
    }
  }

  errorOccurredOnModalInit(modalName: string): boolean {
    // If modal being opened is the edit modal, but no template is selected, return true
    if (modalName === this.editTemplateModalName && this.templateService.currentTemplatePreview.value === undefined) {
      this.alertService.addAlert('You need to select a receiver, project, language and template first!');
      return true;
    } else {
      return false;
    }
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  showModal(modalName: string): void {
    this.modalName.next(modalName);
  }

  hideModal(): void {
    this.modalName.next('');
  }

  setModalTemplateValues(modalName: string) {
    let currentTemplatePreview = this.templateService.currentTemplatePreview.value;

    if (modalName === this.editTemplateModalName && currentTemplatePreview !== undefined && this.templateService.projects) {
      this.templateService.newTemplateName = currentTemplatePreview.name;
      this.templateService.newTemplateProject = currentTemplatePreview.project;
      this.templateService.newTemplateType = currentTemplatePreview.type;
    }
    else {
      this.templateService.newTemplateName = '';
      this.templateService.newTemplateProject = undefined;
      this.templateService.newTemplateType = undefined;
    }
  }

}
