import { Injectable } from '@angular/core'
import { svgs } from "../app.constants"

@Injectable({
  providedIn: 'root'
})
export class SvgService {

  readonly svgs = svgs;
  constructor() { }
}
