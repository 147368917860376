import {Component} from '@angular/core'
import {TemplateService} from "../../services/template.service"
import {LoadingService} from "../../services/loading.service"

@Component({
  selector: 'app-mjml-input',
  templateUrl: './mjml-input.component.html',
  styleUrls: ['./mjml-input.component.scss']
})
export class MjmlInputComponent {

  constructor(public templateService: TemplateService) {
  }


  getMjmlInput(): string {
    if (this.templateService.selectedReceiver === undefined) {
      return 'Please select a receiver'
    }
    if (this.templateService.selectedProject === undefined) {
      return 'Please select a project'
    }
    if (this.templateService.selectedLanguage === undefined) {
      return 'Please select a language'
    }
    if (this.templateService.currentTemplatePreview.value === undefined) {
      return 'Please select a template'
    } else {
      this.updateHtmlPreview()
      if (this.templateService.currentTemplatePreview.value.mjml_content !== undefined) {
        return this.templateService.currentTemplatePreview.value.mjml_content
      } else {
        return 'Selected template mjml content is undefined'
      }
    }
  }

  updateHtmlPreview() {
    this.templateService.convertMjmlToHtml()
    let template = this.templateService.currentTemplatePreview.value
    if (template === undefined) {
      return
    }

    if (this.templateService.htmlOutputTranslated !== undefined && this.templateService.mjmlInput !== undefined) {
      this.templateService.currentTemplatePreview.next({
        id: template.id,
        name: template.name,
        project_id: template.project_id,
        type_id: template.type_id,
        mjml_content: this.templateService.mjmlInput,
        html_content: this.templateService.htmlOutputTranslated,
        translation_key: template.translation_key,
        type: template.type,
        project: template.project,
      })
    }
  }

}
