import {Injectable, Injector} from '@angular/core';
import {
  ItemObject,
  JsonResponse, KeyValueObject,
  LanguageObject,
  MjmlToHtmlOutput,
  TemplateObject,
  TranslationKeyObject,
} from "../interfaces/user-interfaces";
import {BehaviorSubject, lastValueFrom} from "rxjs";
import {UserService} from "./user.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {UtilityService} from "./utility.service";
import {AlertService} from "./alert.service";
import {project_urls, template_urls, textkeys_urls} from "../app.constants";
import {ModalService} from "./modal.service";
import {LoadingService} from "./loading.service";

const mjml2html: Function = require('mjml-browser');

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  //region Vars
  currentTemplatePreview = new BehaviorSubject<TemplateObject | undefined>(undefined);
  mjmlInput: string = '';
  htmlOutputTranslated: string | undefined;
  htmlOutputWithTextKeys: string | undefined;
  placeholdersObject: Record<string, string> = {};
  subject: string = '';

  types: ItemObject[] | undefined;
  projects: ItemObject[] | undefined;
  languages: LanguageObject[] | undefined;
  templates: TemplateObject[] | undefined;
  uniqueReceivers: ItemObject[] | undefined;

  selectedProject: ItemObject | undefined;
  selectedReceiver: ItemObject | undefined;
  selectedLanguage: LanguageObject | undefined;
  selectedTemplate: TemplateObject | undefined;

  newTemplateName: string | undefined = '';
  newTemplateProject: ItemObject | undefined;
  newTemplateType: ItemObject | undefined;

  newProjectName: string | undefined;

  // Headers
  headers = new HttpHeaders()
    .set('Content-Type', 'application/json');

  //endregion

  constructor(private http: HttpClient, private userService: UserService, private utilityService: UtilityService, private alertService: AlertService, private injector: Injector, private loadingService: LoadingService) {
  }


  getPlaceholderKey(index: number, placeholder: KeyValueObject) {
    return placeholder.key
  }

  addPlaceholder(placeholderName: string, placeholderValue: string) {
    if (placeholderName && placeholderValue && this.placeholdersObject !== undefined) {
      this.placeholdersObject[placeholderName] = placeholderValue
    }
  }

  removePlaceholder(placeholderName: string) {
    if (placeholderName !== null && this.placeholdersObject !== undefined) {
      delete this.placeholdersObject[placeholderName]
    }
  }

  setFullTemplate(): void {
    this.loadingService.startLoading();
    if (this.currentTemplatePreview.value !== undefined) {
      this.getFullTemplateById()
        .then((response: JsonResponse) => {
          if (response.error && response.message) {
            this.alertService.addAlert(response.message);
            if (response.template_html) {
              this.htmlOutputTranslated = response.template_html
            }
          }
          if (this.currentTemplatePreview.value && response.template !== undefined) {
            this.currentTemplatePreview.next(response.template);
            this.htmlOutputTranslated = response.template.html_content
          }
          this.loadingService.finishLoading();
        })
        .catch(errorResponse => {
          this.alertService.handleErrorResponse(errorResponse);
          this.loadingService.finishLoading();
        })

    } else {
      this.alertService.addAlert('You need to select a template first!')
    }
  }

  async getAllTextKeysByProjectCall(projectId: number): Promise<JsonResponse> {
    return await lastValueFrom(this.http.post<JsonResponse>(textkeys_urls.getAllByProjectIdUrl, {
        project_id: projectId
      }, {
        headers: this.userService.getAuthHeaders(),
        observe: 'body',
        responseType: 'json'
      }
    ));
  }

  getAllTextKeysByProject(projectId: number) {
    this.loadingService.startLoading();
    this.getAllTextKeysByProjectCall(projectId).then(response => {
      this.alertService.handleResponse(response);
      if (response.text_keys_json === undefined) {
        this.loadingService.finishLoading();
        this.alertService.addAlert('Retrieving text keys was unsuccesful, check the network tab for more information');
        return;
      }
      navigator.clipboard.writeText(JSON.stringify(response.text_keys_json)).then(response => {
        this.alertService.addAlert('Text keys copied to clipboard succesfully');
        this.loadingService.finishLoading();
      }).catch(errorResponse => {
        this.alertService.addAlert('Copying text keys to clipboard was unsuccesful');
        this.loadingService.finishLoading();
      });
      this.loadingService.finishLoading();
    }).catch(errorResponse => {
      this.alertService.handleResponse(errorResponse);
      this.loadingService.finishLoading();
    })
  }

  async getAllCall(endpointUrl: string): Promise<JsonResponse> {
    return await lastValueFrom(this.http.get<JsonResponse>(endpointUrl,
      {headers: this.userService.getAuthHeaders(), observe: 'body', responseType: 'json'}));
  }

  async getAllByProjectIdCall(projectId: number): Promise<JsonResponse> {
    return await lastValueFrom(this.http.post<JsonResponse>(template_urls.getAllByProjectIdUrl, {
        project_id: projectId
      }, {
        headers: this.userService.getAuthHeaders(),
        observe: 'body',
        responseType: 'json'
      }
    ));
  }

  getTemplatesByProject(): void {
    if (this.selectedProject === undefined) {
      return;
    }
    this.loadingService.startLoading();

    this.getAllByProjectIdCall(this.selectedProject.id)
      .then(response => {
        if (response.error && response.message) {
          this.templates = undefined;
          this.selectedTemplate = undefined;
          this.currentTemplatePreview.next(undefined);
          this.alertService.addAlert(response.message);
        }
        if (response.templates !== undefined) {
          this.templates = response.templates;
        }
        this.loadingService.finishLoading();
      })
      .catch(errorResponse => {
        this.templates = undefined;
        this.alertService.handleErrorResponse(errorResponse);
        this.loadingService.finishLoading();
      })
  }

  async syncTranslationsCall(projectName: string) {
    return await lastValueFrom(this.http.post<JsonResponse>(template_urls.syncTranslationsUrl,
      {
        'project_name': projectName
      }, {
        headers: this.userService.getAuthHeaders(),
        observe: 'body',
        responseType: 'json'
      }
    ));
  }

  syncTranslations(project: ItemObject): void {
    this.loadingService.startLoading();

    this.syncTranslationsCall(project.name)
      .then(response => {
        if (response.error === true) {
          if (response.message){
            this.alertService.addAlert(response.message);
          }
        }
        this.loadingService.finishLoading();
      })
      .catch(errorResponse => {
        this.alertService.handleErrorResponse(errorResponse);
        this.loadingService.finishLoading();
      })
  }

  async getTemplateByIdCall(templateId: number, languageAbbreviation: string) {
    return await lastValueFrom(this.http.post<JsonResponse>(template_urls.getByIdUrl,
      {
        id: templateId,
        language: languageAbbreviation,
        receiver: this.selectedReceiver?.name
      }, {
        headers: this.userService.getAuthHeaders(),
        observe: 'body',
        responseType: 'json'
      }
    ));
  }

  async createTemplateCall(name: string, projectId: number, typeId: number) {
    return await lastValueFrom(this.http.post<JsonResponse>(template_urls.createNewUrl,
      {name: name, project_id: projectId, type_id: typeId},
      {headers: this.userService.getAuthHeaders(), observe: 'body', responseType: 'json'}));
  }

  createTemplate() {
    this.loadingService.startLoading();
    if (this.newTemplateName === undefined ||
      this.newTemplateProject === undefined ||
      this.newTemplateType === undefined) {
      this.alertService.addAlert('Either name, project or type was not given. New template cannot be created without this data!');
      this.loadingService.finishLoading();
      return;
    }

    this.createTemplateCall(this.newTemplateName, this.newTemplateProject.id, this.newTemplateType.id)
      .then(response => {
        this.alertService.handleResponse(response);
        if (response.template === undefined || response.error) {
          return;
        }
        if (response.template.project_id === undefined) {
          return;
        }
        if (this.projects === undefined) {
          return;
        }
        if (response.template.mjml_content === undefined) {
          return;
        }


        // Set selected project to project of newly created template
        this.selectedProject = this.newTemplateProject;
        // this.selected_project = this.utilityService.getObjectById(response.template.project_id, this.projects)
        // Get templates for project that newly created template was made in
        this.getTemplatesByProject();
        // Set template values
        this.currentTemplatePreview.next(response.template);
        this.mjmlInput = response.template.mjml_content;
        this.selectedTemplate = response.template;

        // Close modal after creating template
        const modalService = this.injector.get(ModalService);
        modalService.toggleModalIfPossible(modalService.createTemplateModalName);
        // Reset values to stop them from affecting select behaviour
        this.resetNewResourceData();

        this.loadingService.finishLoading();
      }).catch(errorResponse => {
      this.alertService.handleErrorResponse(errorResponse);
      this.loadingService.finishLoading();
    })
  }

  resetNewResourceData() {
    this.newTemplateProject = undefined;
    this.newTemplateName = undefined;
    this.newTemplateType = undefined;
    this.newProjectName = undefined;
  }

  async createProjectCall(name: string) {
    return await lastValueFrom(this.http.post<JsonResponse>(project_urls.createNewUrl,
      {name: name},
      {headers: this.userService.getAuthHeaders(), observe: 'body', responseType: 'json'}));
  }

  createProject(): void {
    this.loadingService.startLoading();
    if (this.newProjectName === undefined) {
      return this.alertService.addAlert('Please input a project name first!');
    }
    this.createProjectCall(this.newProjectName)
      .then(response => {
        this.alertService.handleResponse(response);
        if (response.project === undefined) {
          return;
        }

        this.projects?.push(response.project);
        this.selectedProject = response.project;

        this.templates = undefined;
        this.getTemplatesByProject();
        this.selectedTemplate = undefined;
        const modalService = this.injector.get(ModalService);
        modalService.toggleModalIfPossible(modalService.createProjectModalName);

        this.loadingService.finishLoading();
      }).catch(errorResponse => {
      this.alertService.handleErrorResponse(errorResponse);
      this.loadingService.finishLoading();
    })
  }

  async updateTemplateCall(id: number, name?: string, projectId?: number, typeId?: number) {
    return await lastValueFrom(this.http.put<JsonResponse>(template_urls.editByIdUrl,
      {id: id, name: name, project_id: projectId, type_id: typeId},
      {headers: this.userService.getAuthHeaders(), observe: 'body', responseType: 'json'}));
  }

  updateTemplate(): void {
    this.loadingService.startLoading();
    if (this.currentTemplatePreview.value !== undefined) {
      this.updateTemplateCall(
        this.currentTemplatePreview.value.id,
        this.newTemplateName,
        this.newTemplateProject?.id,
        this.newTemplateType?.id
      ).then(
        (response: JsonResponse) => {
          this.currentTemplatePreview.next(response.template);
          this.selectedTemplate = response.template;
          this.selectedProject = this.newTemplateProject;
          this.getTemplatesByProject();
          this.resetNewResourceData();
          this.loadingService.finishLoading();
        }).catch((error_response => {
        this.alertService.handleErrorResponse(error_response);
      }));
      // Reload Templates and close modal
      const modalService = this.injector.get(ModalService);
      modalService.toggleModalIfPossible(modalService.editTemplateModalName);
    }
  }

  setPlaceholders(placeholders: string[]) {
    this.loadingService.startLoading();
    for (let placeholder of placeholders) {
      if (placeholder === 'pixel_image_url') {
        continue;
      }
      if (placeholder === 'preview') {
        this.placeholdersObject[placeholder] = ''
      }
      else {
        this.placeholdersObject[placeholder] = ''
        // this.placeholdersObject[placeholder] = placeholder + '_value';
      }
    }
    this.loadingService.finishLoading();
  }

  async getFullTemplateById(): Promise<JsonResponse> {
    if (this.subject === undefined) {
      this.subject = 'Subject Value';
    }
    if (this.currentTemplatePreview.value === undefined) {
      return {
        error: true,
        message: 'You need to select a template and input a subject to receive a rendered template.'
      }
    }
    return await lastValueFrom(this.http.post<JsonResponse>(template_urls.getFullByIdUrl,
      {
        id: this.currentTemplatePreview.value.id,
        language: this.selectedLanguage?.abbreviation,
        subject: this.subject,
        placeholders: this.placeholdersObject,
        receiver: this.selectedReceiver?.name
      },
      {headers: this.userService.getAuthHeaders(), observe: 'body', responseType: 'json'}))
  }

  convertMjmlToHtml(): void {
    if (this.mjmlInput === undefined || this.currentTemplatePreview.value === undefined) {
      this.htmlOutputTranslated = 'You have not selected a template or your mjml input is empty!';
      return;
    }
    try {
      let mjmlToHtmlOutput: MjmlToHtmlOutput = mjml2html(this.mjmlInput);
      this.htmlOutputWithTextKeys = mjmlToHtmlOutput.html;
      this.htmlOutputTranslated = mjmlToHtmlOutput.html;

      if (this.currentTemplatePreview.value.translation_key !== undefined) {
        for (let translationObject of this.currentTemplatePreview.value.translation_key) {
          this.translateHtmlOutput(translationObject);
        }
      }
    } catch (e: unknown) {
      // Dont show alert message because the error 'parsing failed, check your MJML' occurs even when everything seems to be fine
      // this.alertService.setAlertMessage(e as string)
      // console.log(e as string)
    }
  }


  translateHtmlOutput(translationObject: TranslationKeyObject) {
    if (this.htmlOutputTranslated === undefined) {
      return;
    }
    if (translationObject.translation.length < 0) {
      return;
    }
    let translatedText = 'TRANSLATION MISSING :/';

    for (let translation of translationObject.translation) {
      if (translation.language_id === this.selectedLanguage?.id &&
        translation.receiver === this.selectedReceiver?.name) {
        translatedText = translation.translated_text;
        break;
      }
    }

    this.htmlOutputTranslated = this.htmlOutputTranslated.replace(translationObject.translation_key, translatedText);
  }

  async updateMjmlAndHtmlCall(currentTemplatePreviewValue: TemplateObject, mjmlInput: string, htmlOutputWithTextKeys: string): Promise<JsonResponse> {
    return await lastValueFrom(this.http.put<JsonResponse>(template_urls.editByIdUrl, {
        id: currentTemplatePreviewValue.id,
        mjml_content: mjmlInput,
        html_content: htmlOutputWithTextKeys
      }, {
        headers: this.userService.getAuthHeaders(),
        observe: 'body',
        responseType: 'json'
      }
    ));
  }

  saveMjmlAndHtml(): void {
    this.loadingService.startLoading();
    if (this.currentTemplatePreview.value === undefined || this.htmlOutputWithTextKeys === undefined) {
      this.alertService.addAlert('You need to select a template first!');
      this.loadingService.finishLoading();
      return;
    }

    this.updateMjmlAndHtmlCall(this.currentTemplatePreview.value, this.mjmlInput, this.htmlOutputWithTextKeys)
      .then((response: JsonResponse) => {
        const modalService = this.injector.get(ModalService);
        modalService.toggleModalIfPossible(modalService.confirmActionSaveModalName);
        // if (this.currentTemplatePreview.value === undefined) {
        //   this.loadingService.finishLoading()
        // }
        this.alertService.handleResponse(response);
        this.loadingService.finishLoading();
      })
  }

  public refreshTemplate(): void {
    this.loadingService.startLoading();
    if (this.selectedTemplate === undefined || this.selectedLanguage?.abbreviation === undefined) {
      this.loadingService.finishLoading();
      return;
    }

    this.getTemplateByIdCall(this.selectedTemplate.id, this.selectedLanguage.abbreviation)
      .then((response: JsonResponse) => {
        this.alertService.handleResponse(response);
        this.setMjmlInputAndHtmlOutputIfCurrentTemplatePreviewNotUndefined(response.template);
        this.setTemplatePreviewIfResponseTemplateNotUndefined(response);
        this.setPlaceholdersIfTemplateOfTypeContent(response); // Run finish loading inside of this function to stop loading from finishing early
      })
      .catch(errorResponse => {
        this.setPlaceholdersIfTemplateOfTypeContent(errorResponse);
      });
  }

  setTemplatePreviewIfResponseTemplateNotUndefined(response: JsonResponse) {
    if (response.template !== undefined) {
      this.currentTemplatePreview.next(response.template);
    }
  }

  setPlaceholdersIfTemplateOfTypeContent(response: JsonResponse) {
    this.loadingService.startLoading();
    if (response.template?.type?.id === 1) {
      // Set the placeholder object to be empty to not infinitely stack placeholders when changing between templates
      this.placeholdersObject = {};
      this.getFullTemplateById().then((response: JsonResponse) => {
        this.handlePlaceholders(response);
        this.loadingService.finishLoading();
      })
        .catch(errorResponse => {
          this.handlePlaceholders(errorResponse.error);
          this.loadingService.finishLoading();
        })
    } else {
      this.placeholdersObject = {};
      this.loadingService.finishLoading();
    }
  }

  handlePlaceholders(response: JsonResponse) {
    if (response.error && response.message !== undefined && response.forgotten_placeholders === undefined) {
      this.alertService.addAlert(response.message);
    }
    if (response.forgotten_placeholders !== undefined) {
      this.setPlaceholders(response.forgotten_placeholders);
    }
  }

  setMjmlInputAndHtmlOutputIfCurrentTemplatePreviewNotUndefined(template: TemplateObject | undefined) {
    if (template === undefined) {
      return;
    }
    this.mjmlInput = template!.mjml_content!;
    this.htmlOutputTranslated = template!.html_content;
    this.htmlOutputWithTextKeys = template!.html_content;
  }

  async deleteProjectCall(projectId: number): Promise<JsonResponse> {
    return await lastValueFrom(this.http.delete<JsonResponse>(project_urls.deleteByIdUrl, {
      headers: this.userService.getAuthHeaders(),
      observe: 'body',
      responseType: 'json',
      body: {id: projectId}
    }))
  }

  deleteProject(projectId: number) {
    this.loadingService.startLoading();
    this.deleteProjectCall(projectId)
      .then(response => {
        this.alertService.handleResponse(response);
        if (this.projects === undefined || response.error) {
          return;
        }
        this.projects = this.utilityService.deleteItemObjectFromArrayByObjectId(this.projects, projectId);
        this.selectedProject = undefined;
        this.loadingService.finishLoading();
      })
      .catch(errorResponse => {
        this.alertService.handleErrorResponse(errorResponse);
        this.loadingService.finishLoading()
      })
  }

  async deleteTemplateCall(templateId: number):
    Promise<JsonResponse> {
    return await lastValueFrom(this.http.delete<JsonResponse>(template_urls.deleteByIdUrl, {
      headers: this.userService.getAuthHeaders(),
      observe: 'body',
      responseType: 'json',
      body: {id: templateId}
    }));
  }

  deleteSelectedTemplate(): void {
    this.loadingService.startLoading();
    if (this.selectedTemplate === undefined) {
      this.alertService.addAlert('You need to select a template first!');
      this.loadingService.finishLoading();
      return;
    }

    this.deleteTemplateCall(this.selectedTemplate.id)
      .then((response) => {
        const modalService = this.injector.get(ModalService);
        modalService.toggleModalIfPossible(modalService.confirmActionSaveModalName);
        this.alertService.handleResponse(response);
        if (this.templates === undefined || this.selectedTemplate === undefined) {
          return;
        }
        this.templates = this.utilityService.deleteItemObjectFromArrayByObjectId(this.templates, this.selectedTemplate.id);
        this.selectedTemplate = undefined;
        this.mjmlInput = '';
        this.currentTemplatePreview.next(undefined);
        this.loadingService.finishLoading();
      })
  }


}
