import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  currentlyLoading: boolean = false;

  constructor() {
  }

  startLoading() {
      this.currentlyLoading = true;
  }

  finishLoading() {
      this.currentlyLoading = false;
  }
}
