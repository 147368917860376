import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {UserService} from './user.service';
import {AlertService} from './alert.service';
import {LoadingService} from "./loading.service";

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivateChild, CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
    private alertService: AlertService,
    private loadingService: LoadingService
  ) {
  }

  canActivateChild(): Promise<boolean> | boolean {
    this.loadingService.startLoading();
    return this.userService.isUserAuthorized().then(
      response => {
        this.userService.loggedInUser = response.user;
        this.loadingService.finishLoading();
        return true;
      },

      (error_response) => {
        this.alertService.handleErrorResponse(error_response);
        this.router.navigate(['/login']);
        this.loadingService.finishLoading();
        return false;
      }
    )
  }

  // noinspection JSUnusedGlobalSymbols
  canActivate() {
    return this.canActivateChild();
  }
}
