import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TemplateService} from "../../services/template.service";
import {ItemObject} from "../../interfaces/user-interfaces";
import {UtilityService} from 'src/app/services/utility.service';
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-generic-select[item_name][items_array][ng_model]',
  templateUrl: './generic-select.component.html',
  styleUrls: ['./generic-select.component.scss']
})
export class GenericSelectComponent {

  @Input() label_text: string | undefined;
  @Input() item_name!: string;
  @Input() items_array!: ItemObject[] | undefined;

  @Input() ng_model!: ItemObject | undefined;
  @Output() ng_modelChange: EventEmitter<ItemObject> = new EventEmitter<ItemObject>();

  constructor(public templateService: TemplateService, public utilityService: UtilityService, public alertService: AlertService) {
  }

  emitNgModel() {
    this.ng_modelChange.emit(this.ng_model);

    if (this.ng_model === this.templateService.selectedTemplate ||
      this.ng_model === this.templateService.selectedReceiver ||
      this.ng_model === this.templateService.selectedLanguage) {
      this.templateService.refreshTemplate();
    } else if (this.ng_model === this.templateService.selectedProject &&
      // Check to stop templates from refreshing when changing the value in a modal
      this.templateService.newTemplateProject !== this.templateService.selectedProject) {
      this.templateService.currentTemplatePreview.next(undefined);
      this.templateService.mjmlInput = '';
      this.templateService.selectedTemplate = undefined;
      this.templateService.getTemplatesByProject();
    }
  }
}
