import { Component } from '@angular/core'
import {UserService} from "../../services/user.service"
import {TemplateService} from "../../services/template.service"

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {

  constructor(public userService: UserService, public templateService: TemplateService) { }

}
