import { Component } from '@angular/core'
import {UserService} from "../../services/user.service"
import {AlertService} from "../../services/alert.service"
import {Router} from "@angular/router"
import {SvgService} from "../../services/svg.service"
import {LoadingService} from "../../services/loading.service"

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent {

  constructor(public userService:UserService, private alertService: AlertService, public router: Router, public svgService: SvgService, private loadingService: LoadingService) { }

  logout(): void {
    this.loadingService.startLoading();
    this.userService.logout().then(
      (response) => {
        this.alertService.handleResponse(response);
        localStorage.removeItem('bearer_token');
        this.router.navigate(['/login']);
        this.loadingService.finishLoading()
      },

      (error_response) => {
        this.alertService.handleErrorResponse(error_response);
        this.loadingService.finishLoading()
      }
    )
  }

}
