import {Injectable} from '@angular/core';
import {BulkMailHistoryObject, BulkMailHistoryResponse, JsonResponse} from "../interfaces/user-interfaces";
import {lastValueFrom} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {UserService} from "./user.service";
import {TemplateService} from "./template.service";
import {email_urls} from "../app.constants";
import {AlertService} from "./alert.service";
import {LoadingService} from "./loading.service";

@Injectable({
  providedIn: 'root'
})
export class MailService {

  recipientEmail: string = '';
  recipientEmailList: string = '';
  bulkMailHistories: BulkMailHistoryObject[] | undefined;
  campaignName: string = '';
  campaignDescription: string = '';

  constructor(private http: HttpClient, private userService: UserService, private templateService: TemplateService, private alertService: AlertService, private loadingService: LoadingService) {
  }

  async sendMailCall(): Promise<JsonResponse | void> {
    if (this.templateService.currentTemplatePreview.value === undefined) {
      this.alertService.addAlert('Please select a template first!');
      return;
    }
    return await lastValueFrom(this.http.post<JsonResponse>(email_urls.sendMailUrl,
      {
        id: this.templateService.currentTemplatePreview.value.id,
        language: this.templateService.selectedLanguage?.abbreviation,
        subject: this.templateService.subject,
        recipient: this.recipientEmail,
        placeholders: this.templateService.placeholdersObject,
        receiver: this.templateService.selectedReceiver?.name
      }, {
        headers: this.userService.getAuthHeaders(),
        observe: 'body',
        responseType: 'json'
      }
    ))
  }

  sendMail(): void {
    this.loadingService.startLoading();
    if (this.templateService.currentTemplatePreview.value !== undefined) {
      this.sendMailCall().then(response => {
        if (response === undefined) {
          this.loadingService.finishLoading();
          return;
        }
        this.alertService.handleResponse(response);
        this.loadingService.finishLoading();
      }).catch(errorResponse => {
        this.alertService.handleErrorResponse(errorResponse);
        this.loadingService.finishLoading();
      })
    }
    this.loadingService.finishLoading();
  }


  async sendBulkMailCall(): Promise<JsonResponse | void> {
    if (this.templateService.currentTemplatePreview.value === undefined) {
      this.alertService.addAlert('Please select a template first!');
      return;
    }
    return await lastValueFrom(this.http.post<JsonResponse>(email_urls.sendBulkMailUrl,
      {
        id: this.templateService.currentTemplatePreview.value.id,
        recipient_list: this.recipientEmailList,
        campaign_name: this.campaignName,
        campaign_description: this.campaignDescription,
        language: this.templateService.selectedLanguage?.abbreviation,
        subject: this.templateService.subject,
        placeholders: this.templateService.placeholdersObject,
        receiver: this.templateService.selectedReceiver?.name
      }, {
        headers: this.userService.getAuthHeaders(),
        observe: 'body',
        responseType: 'json'
      }
    ))
  }

  sendBulkMail(): void {
    if (this.templateService.currentTemplatePreview.value !== undefined) {
      this.loadingService.startLoading();
      this.sendBulkMailCall().then(response => {
        if (response === undefined) {
          this.loadingService.finishLoading();
          return;
        }
        this.getBulkMailHistory();
        this.alertService.handleResponse(response);
        this.loadingService.finishLoading();
      }).catch(errorResponse => {
        this.alertService.handleErrorResponse(errorResponse);
        this.loadingService.finishLoading();
      })
    }
  }

  async getBulkMailHistoryCall(): Promise<BulkMailHistoryResponse> {
    return await lastValueFrom(this.http.get<BulkMailHistoryResponse>(email_urls.getBulkMailHistory, {
        headers: this.userService.getAuthHeaders(),
        observe: 'body',
        responseType: 'json'
      }
    ))
  }

  getBulkMailHistory(): void {
    this.loadingService.startLoading();
    this.getBulkMailHistoryCall().then(response => {
      if (response === undefined) {
        this.loadingService.finishLoading();
        return;
      }
      this.bulkMailHistories = response.bulk_mail_history;
      this.alertService.handleResponse(response);
      this.loadingService.finishLoading();
    }).catch(errorResponse => {
      this.alertService.handleErrorResponse(errorResponse);
      this.loadingService.finishLoading();
    });

    this.loadingService.finishLoading();
  }


  async sendAllMailsCall(): Promise<JsonResponse | void> {
    if (this.templateService.selectedProject === undefined ||
      this.templateService.selectedLanguage === undefined ||
      this.templateService.selectedReceiver === undefined) {
      this.alertService.addAlert('You need to select a project and language first!');
      return;
    }
    return await lastValueFrom(this.http.post<JsonResponse>(email_urls.sendAllMailUrl,
      {
        recipient: this.recipientEmail,
        project_id: this.templateService.selectedProject.id,
        language: this.templateService.selectedLanguage.abbreviation,
        placeholders: this.templateService.placeholdersObject,
        receiver: this.templateService.selectedReceiver.name
      }, {
        headers: this.userService.getAuthHeaders(),
        observe: 'body',
        responseType: 'json'
      }
    ))
  }

  sendAllMails(): void {
    this.loadingService.startLoading();
    this.sendAllMailsCall().then(response => {
      if (response === undefined) {
        return
      }
      this.alertService.handleResponse(response);
      this.loadingService.finishLoading();
    }).catch(errorResponse => {
      this.alertService.handleErrorResponse(errorResponse);
      this.loadingService.finishLoading();
    })
    // TODO if (response.error) {
    //   this.alertService.setErrors({'Template ID': response.template?.id, 'Template Name': response.template?.name}) // Show the ID of the template
    // }
  }

}

