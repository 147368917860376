<div class="text-with-input-horizontal">
  <label for="name"> {{ label_text }} </label>
  <input id="name" type="text" *ngIf="!placeholder" placeholder="Enter {{label_text}}" name="name"
         [(ngModel)]="ng_model"
         (ngModelChange)="this.emitNgModel()">

  <input id="name" type="text" *ngIf="placeholder" placeholder="{{placeholder}}" name="name"
         [(ngModel)]="ng_model"
         (ngModelChange)="this.emitNgModel()">
</div>
