import {Component, Input} from '@angular/core'
import {UserService} from "../../services/user.service";
import {Router} from "@angular/router";
import {SvgService} from "../../services/svg.service";

@Component({
  selector: 'app-primary-button[text]',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss']
})
export class PrimaryButtonComponent {
  @Input() text!: string;
  @Input() type: string | undefined;
  @Input() routerLink: string | undefined; // The url to route to when button is press
  @Input() activeUrlLink: string = ""; // The url that needs to be inside of router.url so that [ngStyle] is evaluated to true
  @Input() svgPathD: string | undefined; // the d attribute of the svg>path for the svg you want to use.
  @Input() svgViewBox: string | undefined; // svg viewBox of the svg you want to use,

  constructor(public userService:UserService, public router: Router) {
  }

}
