import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'reverseArray'
})
export class ReverseArrayPipe implements PipeTransform {

  // Reference: https://stackoverflow.com/questions/35703258/invert-angular-2-ngfor/68803695#68803695

  transform<T>(value: T[]): Iterable<T> {
    return {
      *[Symbol.iterator]() {
        for (let i = value.length - 1; i >= 0; i--) {
          yield value[i]
        }
      }
    }
  }

}
