import { Component, OnInit } from '@angular/core';
import {MailService} from "../../services/mail.service";
import {TemplateService} from "../../services/template.service";
import {SvgService} from "../../services/svg.service";
import {ModalService} from "../../services/modal.service";

@Component({
  selector: 'app-bulk-email-page',
  templateUrl: './bulk-email-page.component.html',
  styleUrls: ['./bulk-email-page.component.scss']
})
export class BulkEmailPageComponent implements OnInit {

  constructor(public mailService: MailService, public templateService: TemplateService, public svgService: SvgService, public modalService: ModalService) { }

  ngOnInit(): void {
  }

}
