import {Component} from '@angular/core'
import {TemplateService} from "../../services/template.service"
import {JsonResponse, KeyValueObject} from "../../interfaces/user-interfaces"
import {MailService} from "../../services/mail.service"
import {AlertService} from "../../services/alert.service"
import {SvgService} from "../../services/svg.service"
import {LoadingService} from "../../services/loading.service";

@Component({
  selector: 'app-sendmail-page',
  templateUrl: './sendmail-page.component.html',
  styleUrls: ['./sendmail-page.component.scss']
})
export class SendmailPageComponent {

  constructor(public templateService: TemplateService, public mailService: MailService, private alertService: AlertService, public svgService: SvgService, private loadingService: LoadingService) {
  }

}
