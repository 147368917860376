import { Injectable } from '@angular/core';
import {AlertObject, ItemObject} from "../interfaces/user-interfaces";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  // noinspection JSUnusedGlobalSymbols
  replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  deleteItemObjectFromArrayByObjectId(array: ItemObject[], id: number): ItemObject[] {
    return array.filter(object => object.id !== id);
  }

  deleteAlertObjectFromArrayByObjectId(array: AlertObject[], id: number): AlertObject[] {
    return array.filter(object => object.id !== id);
  }

  compareItems(firstItem: ItemObject, secondItem: ItemObject): boolean {
    if (firstItem && secondItem) {
      return firstItem.id === secondItem.id;
    } else {
      return firstItem === secondItem;
    }
  }

  // noinspection JSUnusedGlobalSymbols
  getObjectById(id: number, array: ItemObject[]) {
    return array.find((object) => {
      return object.id === id;
    });
  }

}
