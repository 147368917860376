<div id="text">
  <h2>SENDING BULK MAILS</h2>
  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna </p>
  <p>aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata </p>
  <p>sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor </p>
  <p>invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
</div>

<hr>

<div id="buttons-container">
  <app-primary-button class="control-btn" text="SEND BULK EMAIL" (click)="this.mailService.sendBulkMail()"></app-primary-button>
  <app-primary-button class="control-btn" text="HISTORY" (click)="this.modalService.toggleModalIfPossible(modalService.bulkMailHistoryModalName)"></app-primary-button>
</div>

<app-generic-modal modal_name="{{modalService.bulkMailHistoryModalName}}"
                   primary_action_text="CLOSE"
                   (primary_action_function)="this.modalService.toggleModalIfPossible(modalService.bulkMailHistoryModalName)">
</app-generic-modal>

<div id="placeholder-template-container">
  <div id="template-container">
    <app-template-preview></app-template-preview>
  </div>

  <div id="placeholders_container" *ngIf="(this.templateService.placeholdersObject | keyvalue)?.length">
    <app-input [(ng_model)]="mailService.recipientEmailList" label_text="Comma Separated E-Mail Address List" placeholder="test@email.com,user@gmail.com">
    </app-input>

    <app-input [(ng_model)]="mailService.campaignName" label_text="Campaign Name" placeholder="My New Campaign">
    </app-input>

    <app-input [(ng_model)]="mailService.campaignDescription" label_text="Campaign Description" placeholder="This is a campaign with the goal of...">
    </app-input>

    <app-input [(ng_model)]="templateService.subject" label_text="Subject">
    </app-input>

    <hr>

    <h3> PLACEHOLDERS </h3>
    <div class="placeholder_with_controls"
         *ngFor="let placeholder of templateService.placeholdersObject | keyvalue; trackBy:templateService.getPlaceholderKey">
      <app-input label_text="{{placeholder.key}}" [(ng_model)]="placeholder.value"
                 (keyup)="templateService.addPlaceholder(placeholder.key, placeholder.value)">

      </app-input>
      <app-primary-button class="delete-placeholder-btn" svgPathD="{{ this.svgService.svgs.delete.svgPathD }}" text=""
                          (click)="templateService.removePlaceholder(placeholder.key)"
                          svgViewBox="{{ this.svgService.svgs.delete.svgViewBox }}">
      </app-primary-button>
    </div>

    <app-primary-button id="insert-btn" text="INSERT" (click)="templateService.setFullTemplate()"></app-primary-button>
  </div>

</div>
