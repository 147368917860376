import { Component } from '@angular/core'
import {TemplateService} from "../../services/template.service"
import {ModalService} from "../../services/modal.service"
import {AlertService} from "../../services/alert.service"
import {SvgService} from "../../services/svg.service"

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss']
})
export class ProjectsPageComponent {

  constructor(public templateService: TemplateService, public modalService: ModalService, public alertService: AlertService, public svgService: SvgService) { }

}
