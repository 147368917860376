<div id="text">
  <h2>MANAGE PROJECTS</h2>
  <p>
    You can create a new project by clicking on the "create" button, or delete a project by clicking on the "delete"
    icon
    corresponding to the project.
    <br> <br>
    If you try to delete a project that contains existing templates, nothing will happen, <br> if you're sure you want
    to
    delete the project make sure to first delete all templates corresponding to the project.
  </p>

</div>

<hr>

<app-primary-button
  id="create-btn"
  text="CREATE"
  (click)="this.modalService.toggleModal(this.modalService.createProjectModalName)"
  svgPathD="{{this.svgService.svgs.add.svgPathD}}"
  svgViewBox="{{this.svgService.svgs.add.svgViewBox}}">
</app-primary-button>


<app-generic-modal
  modal_name="{{this.modalService.createProjectModalName}}"
  primary_action_text="CREATE"
  (primary_action_function)="this.templateService.createProject()">
</app-generic-modal>

<div id="layout">
  <div id="titles">
    <div class="item">ID</div>
    <div class="item">NAME</div>
    <div class="item"></div>
  </div>
  <div class="contents" *ngFor="let project of templateService.projects">
    <div class="item">
      {{project.id}}
    </div>
    <div class="item">
      {{project.name}}
    </div>
    <div class="item">
      <app-primary-button
        class="action-btn"
        text=""
        (click)="this.templateService.syncTranslations(project)"
        svgPathD="{{this.svgService.svgs.sync.svgPathD}}"
        svgViewBox="{{this.svgService.svgs.sync.svgViewBox}}"
      title="Sync Translations">
      </app-primary-button>
      <app-primary-button
        class="action-btn"
        text=""
        (click)="this.templateService.getAllTextKeysByProject(project.id)"
        svgPathD="{{this.svgService.svgs.copy.svgPathD}}"
        svgViewBox="{{this.svgService.svgs.copy.svgViewBox}}"
      title="Copy Text Keys as Json">
      </app-primary-button>
      <app-primary-button
        class="action-btn"
        text=""
        (click)="this.templateService.deleteProject(project.id)"
        svgPathD="{{this.svgService.svgs.delete.svgPathD}}"
        svgViewBox="{{this.svgService.svgs.delete.svgViewBox}}"
        title="Delete Project">
      </app-primary-button>
    </div>
  </div>
</div>

