<div id="text">
  <h2>MANAGE TEMPLATES</h2>
  <p>
    You can create a template by clicking on the "CREATE" button.
    <br> <br>
    If you selected a template you can edit it's name, type (content, footer or master), and project by clicking on the
    "EDIT" button, or delete it by clicking the "DELETE" button.
    <br> <br>
    In the left input field you can see MJML Code which you can edit to configure your selected template, on the right
    you have a preview of the template.
    <br><br>
    For the MJML documentation, click <a href="https://documentation.mjml.io/" target="_blank">here</a>.
    <br><br>
    After you configured your template you can save by clicking the "SAVE" button or if you want to return to how the
    template looked like before you edited the MJML code click the "RESET" button.
  </p>

</div>

<hr>

<div id="template-controls">

  <app-primary-button text="CREATE"
                      (click)="modalService.toggleModalIfPossible(modalService.createTemplateModalName)"
                      svgPathD="{{svgService.svgs.add.svgPathD}}"
                      svgViewBox="{{svgService.svgs.add.svgViewBox}}">

  </app-primary-button>
  <app-generic-modal modal_name="{{modalService.createTemplateModalName}}"
                     primary_action_text="CREATE"
                     (primary_action_function)="templateService.createTemplate()">
  </app-generic-modal>

  <app-primary-button text="EDIT"
                      (click)="modalService.toggleModalIfPossible(modalService.editTemplateModalName)"></app-primary-button>
  <app-generic-modal modal_name="{{modalService.editTemplateModalName}}"
                     primary_action_text="UPDATE"
                     (primary_action_function)="templateService.updateTemplate()">
  </app-generic-modal>

  <app-primary-button text="RESET" (click)="templateService.refreshTemplate()"></app-primary-button>

  <app-primary-button text="SAVE" (click)="modalService.toggleModalIfPossible(modalService.confirmActionSaveModalName)"></app-primary-button>
  <app-generic-modal modal_name="CONFIRM ACTION SAVE"
                     modal_title="Are you sure you want to save the new template content?"
                     primary_action_text="CONFIRM"
                     (primary_action_function)="templateService.saveMjmlAndHtml()"></app-generic-modal>

  <app-primary-button text="DELETE" (click)="modalService.toggleModalIfPossible(modalService.confirmActionDeleteModalName)"></app-primary-button>
  <app-generic-modal modal_name="CONFIRM ACTION DELETE"
                     modal_title="Are you sure you want to delete this template?"
                     primary_action_text="CONFIRM"
                     (primary_action_function)="templateService.deleteSelectedTemplate()"></app-generic-modal>

</div>


<div id="previews">
  <app-mjml-input></app-mjml-input>
  <app-template-preview></app-template-preview>
</div>


